@tailwind base;
@tailwind components;
@tailwind utilities;
body {
	margin: 0;
	font-family: "B-NAZANIN";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: "B-NAZANIN";
}
.rtl-dir {
	direction: rtl;
}
.ant-pagination {
	direction: ltr;
}
/* scroll */
.ant-pagination {
	direction: ltr; /* Antd class to make rtl the pagination */
}
.ant-table-body {
	scrollbar-width: auto;
	scrollbar-color: auto;
}

.ant-table-body::-webkit-scrollbar {
	width: 6px;
}

.ant-table-body::-webkit-scrollbar-track {
	background-color: #e1e6e6;
}

.ant-table-body::-webkit-scrollbar-thumb {
	border-radius: 6px;
	background-color: #888;
	cursor: pointer;
}
