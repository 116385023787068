@import url("../../Styles/StaticComponents/Global.css");
.factor-header {
	width: 90%;
	height: 100px;
	background-color: #ddd;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	margin: 0 auto;
	direction: rtl;
	border-radius: 5px;
	margin-bottom: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	padding: 0 2%;
}
.header-row {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}
.group {
	width: 32%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 5px;
	margin: 2px;
	text-align: right;
	position: relative;
}
.date-option {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	text-align: center;
	direction: ltr;
}
.date-option input {
	width: 60px;
	min-height: 23px;
	border: none;
	outline: none;
	/* border-right: 1px solid #fff; */
	background-color: #ddd;
	text-align: center;
}
.group .group-option {
	padding: 5px;
}
.group-option__select {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 0 2%;
	cursor: pointer;
}
.group .group-option:first-child {
	width: 80px;
	font-size: 14px;
	font-weight: 600;
}

.group .group-option:nth-child(2) {
	border: 1px solid #aaa;
	background-color: #ccc;
	width: 200px;
	min-height: 30px;
	margin-left: auto;
	text-align: center;
}
.group .group-option:nth-child(2):hover {
	border-color: var(--color-third);
}
.group select:hover {
	border-color: var(--color-third);
}
.group select {
	width: 200px;
	margin-left: auto;
	padding: 5px;
	border: none;
	outline: none;
	border: 1px solid #aaa;
	background-color: #ccc;
}
.factor-date {
	width: 100%;
	height: 100%;
	background-color: #ccc;
	border: none;
	cursor: pointer;
}
.searcher {
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	right: 23%;
	top: -20%;
	width: 80%;
	min-height: 300px;
	background-color: #ddd;
	border-radius: 10px;
	z-index: 3;
	box-shadow: 2px 2px 10px 0px var(--color-black);
}
.drug-searcher {
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	right: 18%;
	top: 25px;
	width: 20%;
	min-height: 300px;
	background-color: #ddd;
	border-radius: 10px;
	z-index: 2;
	box-shadow: 2px 2px 10px 0px var(--color-black);
}
.searcher-header,
.searcher-footer {
	width: 100%;
	height: 35px;
	background-color: var(--color-third);
}
.searcher-header {
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 3%;
}
.searcher-footer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 3%;
	color: #fff;
	font-size: 14px;
	font-weight: bold;
}
.customerSearch {
	width: 98%;
	height: 30px;
	padding: 10px 5px;
	border: none;
	outline: none;
}
.searcher-footer {
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}
.searcher-img {
	width: 12px;
	height: 12px;
	cursor: pointer;
}
.searcher-container {
	width: 100%;
	height: 180px;
	overflow-y: scroll;
}
.searcher-body__container:nth-child(even) {
	background-color: #fff;
}
.searcher-body__container:nth-child(odd) {
	background-color: #f8f6ff;
}
.searcher-heading__container,
.searcher-body__container {
	width: 100%;
	height: 30px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
}
.searcher-body__container:hover {
	background-color: #aaa;
	color: #fff;
}
.searcher-heading {
	width: 30%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-weight: bold;
	font-size: 16px;
}
.searcher-drug__heading:nth-child(1) {
	width: 10%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-weight: bold;
	font-size: 13px;
	padding-right: 10px;
}
.searcher-drug__heading:nth-child(2) {
	width: 80%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-weight: bold;
	font-size: 13px;
}
.searcher-drug__heading:nth-child(3) {
	width: 20%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-weight: bold;
	font-size: 13px;
	padding-left: 5px;
}
.searcher-heading:nth-child(2) {
	width: 70%;
}
.searcher-head {
	width: 30%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 14px;
}
.searcher-head2 {
	width: 70%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 14px;
}
.searcher-drug__head {
	width: 10%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 14px;
}
.searcher-drug__head2 {
	width: 70%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 14px;
}
.searcher-drug__head3 {
	width: 20%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 14px;
}
/* factor content */
.factor-heading {
	width: 90%;
	margin: 0 auto;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	text-align: center;
	border: 1px solid #000;
	direction: rtl;
}

.factor-heading span {
	border-left: 1px solid #000;
	background-color: #bbb;
	padding: 5px;
	/* width: 25%; */
	font-size: 14px;
	font-weight: 600;
}
.factor-heading:last-child {
	border-left: none !important;
}
/* save factore section */
.savepopup-container {
	width: 30%;
	height: 420px;
	background-color: #aaa;
	position: absolute;
	z-index: 3;
	top: 18%;
	left: 35%;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	box-shadow: 2px 2px 10px 0px var(--color-black);
}
.savepopup-header {
	width: 100%;
	height: 40px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 2%;
	background-color: var(--color-mashi);
}
.savepopup-content {
	width: 100%;
	height: 330px;
}
.savepopup-row {
	width: 100%;
	height: 40px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 2px;
	padding: 5px;
}
.savepopup-row:nth-child(odd) {
	background-color: #eee;
}
.savepopup-row:nth-child(even) {
	background-color: #ddd;
}
.savepopup-row span:first-child {
	width: 30%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-left: 1px solid var(--color-black);
}
.savepopup-row span:nth-child(2) {
	width: 70%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
}
.savepopup-row input {
	width: 70%;
	height: 100%;
	text-align: right;
	font-size: 16px;
	border: none;
	padding-right: 20px;
}
.savepopup-footer {
	width: 100%;
	height: 60px;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 2%;
	background-color: var(--color-mashi);
}
/* factor content */
.factor-container {
	position: relative;
}
.add-btn {
	position: absolute;
	right: 20%;
	top: 4px;
	cursor: pointer;
	z-index: 2;
	width: 20px;
	height: 20px;
}
.minus-btn {
	position: absolute;
	left: 2%;
	cursor: pointer;
	width: 20px;
	height: 20px;
}
.factor-content__row .minus-btn:first-child {
	display: none;
}
.factor-content {
	width: 90%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	direction: rtl;
}
.factor-search {
	width: "250px";
	direction: "rtl";
}
.factor-content__row input {
	/* width: 11.11%; */
	padding: 1px 5px;
	border: none;
	outline: none;
	border: 1px solid #bbb;
	font-weight: bold;
}
.factor-content__row input:hover {
	background-color: #eee;
}
.factor-content__row input[type="number"] {
	text-align: center;
}
.secondRow {
	background-color: #ddd;
}
.factor-total__cost {
	width: 90%;
	height: 30px;
	margin: 0 auto;
	background-color: #eee;
	direction: rtl;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	border-left: 1px solid #bbb;
	border-top: 1px solid #bbb;
	border-right: 1px solid #bbb;
}
.factor-total__cost span:nth-child(2) {
	width: 10%;
	font-size: 16px;
	font-weight: bold;
	padding-right: 5px;
}
/* factor footer */
.factor-footer {
	width: 90%;
	margin: 0 auto;
	direction: rtl;
	border: 1px solid #1677ff;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	background-color: #1677ff;
	color: #eee;
}
.factor-footer span {
	font-size: 14px;
	font-weight: 600;
	padding: 0 10px;
}
.factor-footer input {
	padding: 5px;
	width: 100%;
	background-color: #ddd;
	border: none;
	outline: none;
	color: #121212;
}
.factor-code {
	width: 5%;
	font-size: 13px;
	font-weight: 600;
	text-align: center;
	cursor: none;
}
.factor-name {
	width: 20%;
}
.factor-explaination {
	width: 15%;
}
.factor-type,
.factor-company,
.factor-contery,
.factor-count,
.factor-cost,
.factor-total {
	width: 10%;
}
